export const blogContentTableColumns = [
  {
    id: "S.No",
    label: "S.No",
    minWidth: 70,
    align: "left",
  },
  {
    id: "title",
    label: "title",
    minWidth: 70,
    align: "left",
  },
  {
    id: "category.title",
    label: "Category",
    minWidth: 70,
    align: "left",
  },
  {
    id: "subCategory.title",
    label: "Sub-category",
    minWidth: 70,
    align: "left",
  },
  // {
  //   id: "image",
  //   label: "Image",
  //   minWidth: 70,
  //   align: "center",
  //   type: "IMAGE",
  // },

  // {
  //   id: "category.title",
  //   label: "Catgory",
  //   minWidth: 70,
  //   align: "center",
  //   type: "text",
  // },
  {
    id: "assets",
    label: "Attachment",
    minWidth: 70,
    align: "center",
    type: "VIDEO",
  },
  {
    id: "thumbnail",
    label: "Thumbnail",
    minWidth: 70,
    align: "center",
    type: "thumbnail",
  },
  {
    id: "authorName",
    label: "Author Name",
    minWidth: 70,
    align: "center",
    type: "text",
  },
  {
    id: "readTime",
    label: "Read Time",
    minWidth: 70,
    align: "center",
    type: "text",
  },
  {
    id: "createdAt",
    label: "Publishing Date",
    minWidth: 70,
    align: "center",
    type: "date",
  },
  {
    id: "details",
    label: "Details",
    minWidth: 70,
    align: "center",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 70,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];
export const blogContentFormFields = [
  {
    name: "title",
    label: "Title",
    type: "text",
    required: true,
  },
  {
    name: "category",
    label: "Category",
    type: "text",
    isMultiSelect: false,
    category: "category",
    options: [],
    required: true,
  },
  {
    name: "subCategory",
    label: "SubCategory",
    type: "text",
    isMultiSelect: false,
    category: "subCategory",
    required: true,
  },
  {
    name: "asset",
    label: "Image",
    type: "file",
    required: true,
  },
  {
    name: "authorName",
    label: "Author Name",
    type: "text",
    requied: true,
  },
  {
    name: "readTime",
    label: "Read Time",
    type: "number",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "description",
    required: true,
  },
];
