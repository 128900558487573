import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const MonthYearSelect = ({
  onMonthYearChange,
  selectedMonth,
  selectedYear,
}) => {
  const currentYear = new Date().getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Create an array of 5 years (2 years back, current year, 2 years forward)
  const years = Array.from(
    { length: 5 },
    (_, index) => currentYear - 2 + index
  );

  const handleMonthChange = (event) => {
    onMonthYearChange(event.target.value, selectedYear);
  };

  const handleYearChange = (event) => {
    onMonthYearChange(selectedMonth, event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" sx={{ minWidth: 120, margin: "1rem" }}>
        {/* <InputLabel htmlFor="month">Select Month</InputLabel> */}
        <Typography variant="h6" color="initial">
          Start date
        </Typography>
        <TextField
          type="date"
          id="startdate"
          // name={field.name}
          // value={getNestedProperty(formData, fieldname)}
          // onChange={(event) => handleChange(field.name)(event)}
          // fullWidth
          // error={!!errors[field.name]}
          // helperText={errors[field.name]}
          // disabled={field.disabled ? field.disabled : false}
        />
      </FormControl>

      <FormControl variant="outlined" sx={{ minWidth: 120, margin: "1rem" }}>
        {/* <InputLabel htmlFor="year">Select Year</InputLabel>
        <Select
          label="Select Year"
          value={selectedYear}
          onChange={handleYearChange}
          id="year"
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select> */}
        <Typography variant="h6" color="initial">
          End date
        </Typography>
        <TextField id="enddate" type="date" />
      </FormControl>
    </div>
  );
};

export default MonthYearSelect;
