import React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import logo1 from "../../assets/images/logo1.png";

import GroupIcon from "@mui/icons-material/Group";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import PsychologyIcon from "@mui/icons-material/Psychology";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LandscapeIcon from "@mui/icons-material/Landscape";
import LayersIcon from "@mui/icons-material/Layers";
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FeedbackIcon from '@mui/icons-material/Feedback';

import DoctorImg from "../../assets/images/medical-assistance.svg"; // Import the DoctorImg SVG

export const sidebarListArr = [
  { label: "Patient List", icon: <GroupIcon />, url: "/Patient" },
  { label: "Doctor List", imageIcon: DoctorImg, url: "/doctor" }, // Update for DoctorImg
  { label: "Diet Content", icon: <DinnerDiningIcon />, url: "/diet" },
  { label: "Gut Content", icon: <PsychologyIcon />, url: "/brain" },
  { label: "Learn Content", icon: <RssFeedIcon />, url: "/blogContnent" },
  { label: "FAQ", icon: <LiveHelpIcon />, url: "/faq" },
  { label: "Test Reports", icon: <SummarizeIcon />, url: "/reports" },
  { label: "Diet Categories", icon: <LandscapeIcon />, url: "/dietcategories" },
  { label: "Gut Categories", icon: <LayersIcon />, url: "/gutcategories" },
  { label: "Learn Categories", icon: <DashboardIcon />, url: "/blogcategories" },
  { label: "Learn Sub Categories", icon: <EventNoteIcon />, url: "/blogsubcategories" },
  { label: "Diet Sub Categories", icon: <FactCheckIcon />, url: "/dietsubcategories" },
  { label: "Gut Sub Categories", icon: <FeaturedPlayListIcon />, url: "/gutsubcategories" },
  { label: "User Feedback", icon: <FeedbackIcon />, url: "/feedback" },
];

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location", location.pathname);
  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Box className={styles.main_div}>
      <Box className={styles.logo_content}>
      <Box className={styles.header_div}>
        <img src={logo1} alt="logo" className={styles.logo} />
      </Box>
      <Typography className={styles.logo_heading}>GUT-BUDDY</Typography>
      </Box>

      <List className={styles.list_div}>
        {sidebarListArr.map((val, index) => (
          <div
            key={index}
            className={
              location.pathname === val?.url
                ? styles.active_icon
                : styles.inactive_icon
            }
          >
            <NavLink
              to={`${val.url}`}
              style={{ textDecoration: "none", color: "darkgray" }}
            >
              <ListItem button key={val.label}>
                <ListItemIcon color="inherit" className={styles.icon_css}>
                  {/* Render the icon or imageIcon */}
                  {val.icon ? (
                    val.icon
                  ) : (
                    <img
                      src={val.imageIcon}
                      alt={val.label}
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={val.label}
                  className={
                    location.pathname === val?.url
                      ? styles.active_url_text
                      : styles.inactive_url_text
                  }
                />
              </ListItem>
            </NavLink>
          </div>
        ))}
      </List>
      <Box className={styles.logout_div}>
        <Button
          onClick={logoutHandler}
          variant="contained"
          className={styles.logout_btn}
        >
          logout
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;
